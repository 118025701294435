/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'
import * as qs from 'qs'
import { get, isString, once, assign } from 'lodash'
import { gsap } from 'gsap'

import { ALL_FLOWS, findFlowIndexById } from '../store/flow'
import { DebugTools } from './DebugTools'
import { useLocalStorage, compile_label, storage_eval, storage_clear, storage_set } from '../store/localstorage'

import { Pixi, PixiHandles, ScreenPlayProp } from './Pixi'
import { Audio, AudioHandles } from './Audio'
import { Flow, NOOP_FLOW_COMPONENT } from '../store/flow.types'

import { Buttons } from './ui/Buttons'
import { Input } from './ui/Input'
import { InputForm } from './ui/InputForm'
import { InputFormFullScreen } from './ui/InputFormFullScreen'
import { Message } from './ui/Message'
import { Textarea } from './ui/Textarea'
import { AgeSelection } from './ui/AgeSelection'
import { AllowShare } from './ui/AllowShare'
import { LessonCategory } from './ui/LessonCategory'
import { Poster } from './ui/Poster'
import { PleaseShare } from './ui/PleaseShare'
import { Noop } from './ui/Noop'
import { Warning } from './ui/Warning'
import { Introduction } from './ui/Introduction'
import { WelcomeHome } from './ui/WelcomeHome'
import { LogoScreen } from './ui/LogoScreen'
import { Empty } from './ui/Empty'
import { Quote } from './ui/Quote'

import { history } from '../store/history'
import { CloseButton } from './ui/CloseButton'
import { useLocation } from 'react-router-dom'

function get_component(name: string) {
  console.log('%crender component = ' + name, 'color: #ef44bb')
  return {
    Buttons,
    Input,
    InputForm,
    Message,
    Textarea,
    AgeSelection,
    InputFormFullScreen,
    AllowShare,
    LessonCategory,
    Poster,
    PleaseShare,
    Noop,
    Warning,
    Introduction,
    WelcomeHome,
    LogoScreen,
    Empty,
    Quote,
  }[name]
}

const is_human_first_interact = new Promise((res) => {
  document.addEventListener('click', once(res))
  document.addEventListener('touch', once(res))
})
export function Journey(): JSX.Element {
  const location = useLocation()
  const query: any = qs.parse(location.search, { ignoreQueryPrefix: true })
  const query_index = query.index === undefined ? 0 : isNaN(query.index) ? findFlowIndexById(query.index) : +query.index
  const query_debug = query.debug == '1'
  const storage = useLocalStorage()
  const audio_el = React.useRef<AudioHandles>(null)
  const pixi_el = React.useRef<PixiHandles>(null as any)
  const paper_el = React.useRef<HTMLDivElement>(null as any)
  const parent_el = React.useRef<HTMLDivElement>(null as any)

  const REGISTER_INDEX = 15

  const [state, setState] = React.useState({
    index: +query_index,
  })
  const curr = React.useMemo(() => {
    const raw_flow = ALL_FLOWS[state.index]
    const curr_flow: Flow = JSON.parse(compile_label(JSON.stringify(raw_flow)))
    if (curr_flow.type == 'reference') {
      history.push(location.pathname + '?index=' + curr_flow.id)
    }
    return curr_flow
  }, [state.index])
  console.log(curr)
  const [curr_component, set_curr_component] = React.useState(NOOP_FLOW_COMPONENT)

  React.useEffect(() => {
    const { name, age, scholar, gender, save_answers } = storage
    if (query_index > 10 && !(name && age && scholar && gender && save_answers)) {
      window.location.href = '/'
    }
  }, [])

  React.useEffect(() => {
    console.log('curr', curr)
    if (curr.type === 'component') {
      const curr_state: any = curr
      if (curr_state.field) {
        curr_state.value = curr_state.value ?? storage[curr_state.file]
      }
      set_curr_component(curr_state)
    }
  }, [curr])

  function _next(id?: string) {
    if (isString(id)) {
      setState((state) => ({ ...state, index: findFlowIndexById(id) }))
    } else {
      setState((state) => ({ ...state, index: state.index + 1 }))
    }
  }
  function raw_next(id?: string) {
    _next(id)
  }
  async function fade_next(id?: string) {
    const tl = gsap.fromTo(
      paper_el.current,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: function () {
          _next(id)
        },
      }
    )
  }

  async function paper_flip() {
    console.warn('[Warning] naruto - paper flip function is deprecated. do nothing')
    raw_next()
  }

  function branch_story() {
    // calculate in localstorage/naruto_storage
    if (storage.story_branch) {
      fade_next(storage.story_branch)
    } else {
      alert('branch story value is not enough to predict branch')
      console.error('branch story value is not enough to predict branch')
    }
  }
  async function play_audio({ source }) {
    // console.warn('skip play_audio for v1')
    await is_human_first_interact
    const audioHandler = () => {
      if (!audio_el.current?.source().includes(source)) {
        console.log('source', audio_el.current?.source(), source)
        audio_el.current?.play(source || null)
        document.body.removeEventListener('click', audioHandler)
      }
    }
    document.body.addEventListener('click', audioHandler)
    raw_next()
  }
  function set_style(query_selector: string, style: any) {
    const el: any = document.querySelector(query_selector)
    if (el) {
      assign(el.style, style)
    } else {
      console.error(`query selector '${query_selector}' is not found`)
    }
    raw_next()
  }
  function sleep(ms: number) {
    setTimeout(() => {
      raw_next()
    }, ms)
  }
  async function pixi_screen(screens: ScreenPlayProp[]) {
    await pixi_el.current.completed
    const async_list: Promise<any>[] = []
    for (const screen of screens) {
      const animation_done = pixi_el.current.play({ ...screen })
      if (screen.async) {
        async_list.push(animation_done)
      }
    }
    await Promise.all(async_list)
    raw_next()
  }
  function clear_storage() {
    storage_clear()
    storage_set('gender', 'none')
    storage_set('scholar', 'none')
    raw_next()
  }

  function next(...rest) {
    if (true) {
      gsap.to(paper_el.current, { autoAlpha: 0, pointerEvents: 'none', onComplete: () => raw_next(...rest) })
    }
  }

  React.useEffect(() => {
    console.log('%cindex = ' + state.index, 'color: #ae5385')
    switch (curr.type) {
      case 'component': {
        gsap.to(paper_el.current, {
          autoAlpha: 1,
          onComplete() {
            gsap.set(paper_el.current, { pointerEvents: 'auto' })
          },
        })
        break
      }
      case 'reference': {
        raw_next()
        break
      }
      case 'redirect': {
        if (curr.if) {
          const pred = storage_eval(curr.if)
          console.log(`value if "${curr.if}" = ${pred}`)
          raw_next(pred ? curr.redirect : undefined)
        } else {
          raw_next(curr.redirect)
        }
        break
      }
      case 'function': {
        const func = get(
          {
            branch_story,
            paper_flip,
            play_audio,
            set_style,
            sleep,
            pixi_screen,
            clear_storage,
          },
          curr.function
        )
        func(...curr.args)
        break
      }
    }
  }, [state.index])

  React.useEffect(() => {
    document.body.style.width = `${window.innerWidth}px`
    document.body.style.height = `${window.innerHeight}px`
    if (process.env.NODE_ENV === 'production') {
      document.body.addEventListener(
        'click',
        once(() => {
          document.documentElement
            .requestFullscreen()
            .then(() => {
              console.error('ok fullscreen')
              document.body.style.width = `${window.innerWidth}px`
              document.body.style.height = `${window.innerHeight}px`
            })
            .catch((err) => {
              console.error('error fullscreen')
              console.error(err)
            })
        })
      )
    }
  }, [])

  return (
    <>
      <Audio ref={audio_el} />
      <div
        className="w-screen h-screen flex justify-center items-center rounded-none md:rounded relative"
        style={{ perspective: 1200, perspectiveOrigin: 'left center' }}
      >
        <div id="paper-wrapper" ref={parent_el}>
          <div id="close-button">{state.index >= REGISTER_INDEX && <CloseButton />}</div>
          <Pixi ref={pixi_el} />
          <div
            id="paper"
            ref={paper_el}
            className="flex justify-center items-center flex-col h-full w-full px-10 relative"
          >
            <div id="component-wrapper">
              {curr_component.type == 'component' &&
                React.createElement(get_component(curr_component.component), { next: next, ...curr_component })}
            </div>
          </div>
        </div>
      </div>
      {query_debug && (
        <DebugTools index={state.index} onIndexChange={(i) => setState((state) => ({ ...state, index: i }))} />
      )}
    </>
  )
}
