import * as React from 'react'
import { useState, KeyboardEvent } from 'react'
import { storage_set } from '../../store/localstorage'

function SelectedItem({ name, remove }: { name: string; remove: () => void }): JSX.Element {
  return (
    <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
      <div className="text-xs font-normal leading-none max-w-full flex-initial">{name.slice(0, name.indexOf('.'))}</div>
      <button type="button" className="flex flex-auto flex-row-reverse" onClick={remove}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      </button>
    </div>
  )
}

function SelectableItem({ name, add, selected }: { name: string; add: () => void; selected?: boolean }) {
  const border = selected ? 'border-teal-600' : 'hover:border-teal-100'
  return (
    <div className="cursor-pointer w-full border-gray-100 border-b hover:bg-teal-100" onClick={add}>
      <div className={'flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative ' + border}>
        <div className="w-full items-center flex">
          <div className="mx-2 leading-6  ">{name} </div>
        </div>
      </div>
    </div>
  )
}

export function MultiSelectDropdown({ field }) {
  const [visibility, setVisibility] = useState<'invisible' | 'visible'>('invisible')
  const [interest, setInterest] = useState([] as string[])
  const [interestValue, setInterestValue] = useState('')
  const [choices, setChoices] = useState([
    '1. ความสัมพันธ์กับเพื่อน',
    '2. ความฝัน',
    '3. อุดมการณ์',
    '4. ความรัก',
    '5. ตัวคุณ',
    '6. ครอบครัว',
    '7. ความเป็นอยู่ ความมั่นคงในชีวิต',
    '8. ความสุข',
    '9. ชื่อเสียง',
    '10. เรื่องเงิน',
    '11. หน้าที่การงาน',
    '12. ไม่มี',
  ])
  const toggleDropdown = () => {
    setVisibility((visibility) => {
      if (visibility === 'invisible') {
        return 'visible'
      } else {
        return 'invisible'
      }
    })
  }

  const addInterest = (name: string) => {
    setInterest((interest) => {
      if (interest.length < 3 && !interest.includes(name)) {
        const newInterest = interest.concat([name])
        storage_set(field, JSON.stringify(newInterest))
        return newInterest
      }
      return interest
    })
  }

  const removeInterest = (indexInChoices: string) => {
    const index = interest.indexOf(choices[parseInt(indexInChoices) - 1])
    setInterest((interest) => {
      const newInterest = index < 0 ? interest : [...interest.slice(0, index), ...interest.slice(index + 1)]
      storage_set(field, JSON.stringify(newInterest))
      return newInterest
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const additionalInterest = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setChoices((choices) => {
        const formatedValue = `${choices.length + 1}. ${interestValue}`
        addInterest(formatedValue)
        setInterestValue('')
        return choices.concat([formatedValue])
      })
    }
  }
  return (
    <div className="w-full flex flex-col items-center max-h-full">
      <div className="w-full">
        <div className="flex flex-col items-center relative">
          <div className="w-full">
            <div className="my-2 p-1 flex border border-gray-200 bg-white rounded">
              <div className="flex flex-auto">
                {interest.map((itemName, key) => (
                  <SelectedItem
                    key={key}
                    name={itemName}
                    remove={() => removeInterest(itemName.slice(0, itemName.indexOf('.')))}
                  />
                ))}

                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="กดเพื่อเพิ่มตัวเลือก"
                    value={interestValue}
                    className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                    readOnly
                    onFocus={() => setVisibility('visible')}
                    // onChange={(e) => setInterestValue(e.target.value)}
                    // onKeyDown={additionalInterest}
                  />
                </div>
              </div>
              <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
                <button
                  className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
                  type="button"
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-up w-4 h-4 deg-180"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`absolute shadow top-100 bg-white z-40 w-full lef-0 rounded h-40 overflow-y-auto ${visibility}`}
          >
            <div className="flex flex-col w-full">
              {choices.map((itemName, key) => (
                <SelectableItem
                  key={key}
                  name={itemName}
                  add={() => addInterest(itemName)}
                  selected={interest.includes(itemName)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
