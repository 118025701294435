import * as React from 'react'
import { ALL_FLOWS_ID, ALL_FLOWS, findFlowIndexById } from '../store/flow'

interface DebugToolsProps {
  index: number
  onIndexChange?: (index: number) => unknown
}

export function DebugTools({ index = 0, onIndexChange }: DebugToolsProps): JSX.Element {
  return (
    <div className="top-0 bg-gray-800 p-4 rounded shadow-lg transform fixed w-full flex justify-center items-center">
      <div className="w-2/3">
        <select
          value={ALL_FLOWS[index]['id'] || ''}
          className="input w-8/12"
          onChange={(e) => onIndexChange && onIndexChange(findFlowIndexById(e.target.value))}
        >
          <option value=""></option>
          {ALL_FLOWS_ID.map((id) => (
            <option value={id} key={id + '-debugtools'}>
              {id}
            </option>
          ))}
        </select>
        <input
          className="input w-4/12"
          type="number"
          value={index}
          onChange={(e) => onIndexChange && onIndexChange(+e.target.value)}
        />
      </div>
    </div>
  )
}
