/* eslint-disable react/prop-types */
import * as React from 'react'
import { naruto_storage } from '../../store/localstorage'
import { BaseForm } from './BaseForm'
import { Input } from './InputForm'

export function AllowShare({ next, field }) {
  const storage = naruto_storage()
  const lifeLesson = storage.life_lesson
  const age = parseInt(storage.age)

  return (
    <BaseForm next={next}>
      <div className="text-xl font-bold px-4 paragraph">{`"${lifeLesson}"`}</div>
      <div className="mt-4 text-center">
        {`ข้อความนี้คือบทเรียนชีวิตอันมีค่า ที่ต้องใช้กว่า ${age} ปีในการกลั่นกรอง คุณอนุญาตให้เราเผยแพร่ให้คนทั่วไป ได้ร่วมเรียนรู้ และร่วมเติบโตไปกับคุณมั้ย`}
      </div>
      <div className="px-12">
        <Input
          label=""
          element="radio"
          field={field}
          choice={[
            { label: 'ยินยอม', value: 'true' },
            { label: 'ไม่ยินยอม', value: 'false' },
          ]}
        />
      </div>
    </BaseForm>
  )
}
