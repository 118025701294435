/* eslint-disable react/prop-types */
import * as React from 'react'
import { InputForm } from './InputForm'

export function InputFormFullScreen(props) {
  return (
    <div className="text-sm">
      <InputForm {...props} />
    </div>
  )
}
