import * as React from 'react'

import { get_story_branch } from '../../store/localstorage'
import nextIconOrange from '../../assets/logo/next-orange-icon.png'
import VichaOf from '../../assets/icon/vicha-of.png'

const quotes = {
  'tale-maker': [
    {
      quote: 'ฉันไม่รู้หรอกว่าในอนาคต\nจะเกิดขึ้นอะไรกับฉัน\nแต่อย่างน้อยที่สุด\nฉันวางใจในตัวเอง',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      quote:
        'การเติบโตคือการยอม\nให้ชีวิตมันขัดเกลาเรา\n.. เราไม่ต้องชอบความทุกข์ก็ได้\nแต่ก็ต้องขอบคุณมัน\nที่มันผ่านเข้ามาในชีวิต\nแล้วพาเราก้าวกระโดดขึ้นไป\nจากชุดประสบการณ์ที่สอนเรา',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      quote: 'ชีวิตสอนให้เรารู้เยอะแยะมาก\nจนอธิบายไม่ถูก\nแต่ฉันจะสู้และก้าวผ่านมันต่อไป',
      signature: '/signature/dny.png',
      by: 'ดนยา จิตจักเมธา (อายุ 18 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      smaller_for_iphone5: true,
      quote:
        'การเติบโตถึงจะมีบาดแผลไปบ้าง\nเราต้องรู้จักรักษาให้มันหาย\nและลุกขึ้นสู้ต่อไป\nบทเรียนชีวิต\nถึงเเม้ว่ามันไม่มีในตำราเรียน\nแต่มันมีคุณค่ามาก\nเพราะบทเรียนชีวิตจะสอนให้เราโตขึ้น\nเเละเป็นคนที่เข้มเเข็ง',
      signature: '/signature/aes.png',
      by: 'นางสาวอาอีซ๊ะ บาสอ (อายุ 19 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'ชีวิตสอนให้เราเข้มเเข็ง\nในวันที่อ่อนเเอที่สุด\nเเละสุดท้าย\nเราจะผ่านทุกอย่าไปได้ด้วยดี',
      signature: '/signature/wpp.png',
      by: 'นางสาววิภาวนี พวงจารี (อายุ 16 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'เราต่างโตขึ้น\nจากความผิดพลาดในชีวิต',
      signature: '/signature/ncy.png',
      by: 'นางสาวณัฐชยา (อายุ 17 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
  ],
  'self-lover': [
    {
      quote:
        'พี่ไม่รู้ว่าการประสบความสำเร็จ\nในชีวิตคืออะไร\nแต่ในวัย 58\nการมีบางสิ่งบางอย่าง\nที่คุณตื่นมาเพื่อทำ\nตื่นเต้น และสนุกสนานในการทำ\nมันดีที่สุดแล้ว',
      signature: '/signature/veeraporn.png',
      by: 'วีรพร (อายุ 58 ปี),\nนักเขียนดับเบิ้ลซีไรต์',
    },
    {
      quote: 'แม้ในความมืดที่มืดที่สุด\nแต่เธอจะยังมองเห็น\nตัวเองอยู่เสมอ\n... เธอจะผ่านทุกเรื่องไปได้',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      quote:
        'ชีวิตมันมีเรื่องหลายอย่าง\nมากกว่าที่ตัวเรามัวเเต่กังวล\nเเละกลัวในสิ่งที่จะเกิด\nมันยังคงมีเรื่องอื่น\nรอคอยเราอยู่เสมอ',
      signature: '/signature/nch.png',
      by: 'นางสาวณัฐณิชา ชุมภู (อายุ 15 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote:
        'การโตเป็นเรื่องยาก\nต้องเรียนรู้ไปเรื่อย ๆ\nบางครั้งมันก็ไม่ได้เป็นแบบที่คิด\nและคาดหวัง\nแต่ถ้าเราพยายาม\nเราก็เรียนรู้ได้',
      signature: '/signature/mle.png',
      by: 'นางสาวมาลี (อายุ 18 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'ชีวิตสอนให้รู้ทุกอย่าง\nที่เราไม่เคยรู้ในชีวิต\nและยังมีอีกมากที่รอให้เราไปรู้',
      signature: '/signature/stn.png',
      by: 'นางสาวสุธินันท์ บุตรศรีพันธ์ (อายุ 15 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
  ],
  'progressive-learner': [
    {
      quote:
        'ไม่ปฏิเสธหรอกว่า\nการเติบโตจะยาก\nแต่กล้าพูดว่ามันจะคุ้มค่า\nแล้วเมื่อไหร่มันคุ้มค่า\nเราจะเติบโตแบบบานสะพรั่ง',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      quote:
        'เราอยู่กับความจริง\nแต่ว่าเราต้องไม่ลืมว่า\nเราฝันได้ เราหวังได้\n... เป็นต้นไม้\nที่ค่อย ๆ คลานไปหาแสง\nแล้วแย้มบาน',
      signature: '/signature/autta.png',
      by: 'AUTTA (อายุ 21 ปี),\nRapper',
    },
    {
      quote: 'ชีวิตสอนให้รู้ว่า\nท้อได้แต่ห้ามถอย\nชีวิตเริ่มต้นใหม่ได้เสมอ\nล้มแล้วต้องลุกด้วยตัวเองได้',
      signature: '/signature/mnw.png',
      by: 'นาวสาวมณีวรรณ โหว่สงคราม (อายุ 15 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'มีความสุขเถอะนะ\nใช้ชีวิตให้มีความสุขที่สุดเลย',
      signature: '/signature/cws.png',
      by: 'ฉัตรวริศรา กลั่งเนียม (อายุ 15 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'ชีวิตสอนให้เรารู้เยอะแยะมาก\nจนอธิบายไม่ถูก\nแต่ฉันจะสู้และก้าวผ่านมันต่อไป',
      signature: '/signature/dny.png',
      by: 'ดนยา จิตจักเมธา (อายุ 18 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
  ],
  'bitter-adult': [
    {
      smaller_for_iphone5: true,
      quote:
        'ไม่เป็นไรที่เธอในวันนั้น\nจะเป็นเด็กแบบนั้น\nเพราะว่าสิ่งแวดล้อมต่าง ๆ\nเป็นสิ่งที่เราเลือกเองไม่ได้\nแต่ก็ค่อย ๆ เรียนรู้ชีวิต\nในแต่ละด้านไปเรื่อย ๆ\nแต่ก็อย่าพึ่งยอมแพ้\nกับการถูกขัดเกลาในชีวิตที่จะเกิดขึ้น\n... อย่าพึ่งคิดว่าตัวเอง\nจะกลายเป็นดอกไม้\nที่บานออกมาแล้วจะไม่สวย\nตราบที่ยังไม่เคยได้ลองบาน\nวันนึงมันอาจจะดีก็ได้',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      quote:
        'พี่อยากเห็น\nตอนที่คุณมีความรัก\nตอนคุณมีแฟน ตอนคุณมีลูก\nอยากให้คุณอยู่ถึงตอนนั้น\nพี่ไม่อยากให้คุณพลาดสิ่งนี้\n... ความเป็นไปได้ในชีวิตคุณ\nมันมีเป็นล้าน\nสิ่งที่ดีที่สุดในชีวิตคุณ\nอาจเป็นแมวซักตัว\nตอนอายุ 60 ก็ได้',
      signature: '/signature/veeraporn.png',
      by: 'วีรพร (อายุ 58 ปี),\nนักเขียนดับเบิ้ลซีไรต์',
    },
    {
      quote:
        'เวลาอายุคุณน้อย ๆ\nคุณรู้สึกเหมือนความเจ็บปวด\nมันจะเป็นนิรันดร์\nแต่เชื่อพี่ แป๊ปเดียว\nเดี๋ยวมันก็จะผ่านไป',
      signature: '/signature/veeraporn.png',
      by: 'วีรพร (อายุ 58 ปี),\nนักเขียนดับเบิ้ลซีไรต์',
    },
    {
      smaller_for_iphone5: true,
      quote:
        'เมื่อไหร่ที่เรารู้สึกแย่\nการร้องไห้\nก็เป็นตัวช่วยที่ดีได้เหมือนกัน\nการฮีลตัวเอง\nจากสิ่งรอบข้างได้ดีที่สุด\nคือการอยู่กับตัวเอง\nและเพลงโปรด\nโลกมี่การเปลี่ยนแปลงอยู่เสมอ\nยอมรับมันซะ\nไม่มีสิ่งไหนยังคงอยู่ได้ตลอด',
      signature: '/signature/sp.png',
      by: 'สิภา (อายุ 20 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
    {
      quote: 'ชีวิตไม่เคยเป็นเรื่องง่าย\nแต่ละวันที่ผ่านไปมันยากมาก\nแต่ถ้าแค่ผ่านวันนี้ไปได้\nวันนี้เราเก่งแล้ว',
      signature: '/signature/pry.png',
      by: 'นางสาวภัทรรียา แซ่คำ (อายุ 17 ปี),\nนักเรียนทุนกสศ.\n(กองทุนเพื่อความเสมอภาคทางการศึกษา)',
    },
  ],
  'sad-adult': [
    {
      quote:
        'ถ้าชีวิตโบยตีเราแล้ว\nเราก็ไม่ต้องโบยตีตัวเองซ้ำ\nณ ขณะนั้น\nก็อยากให้ชื่นชมตัวเองว่า\nที่ผ่านมาเราทำได้ดีมากเลยนะ\nอาจจะมีที่ทำไม่ได้บ้าง\nก็ไม่ผิดแปลกอะไร',
      signature: '/signature/pie.png',
      by: 'พาย (อายุ 29 ปี),\nนักเขียนหนังสือเรื่อง How I love my mother',
    },
    {
      smaller_for_iphone5: true,
      quote:
        'เราถูกทำให้เชื่อว่า ชีวิตมันพังได้\nไม่จริง\nชีวิตมันแข็งแกร่งกว่านั้นมาก\nอย่าให้ข้อจำกัดของสังคม\nมาบอกว่าชีวิตคุณมันพัง\nอยากทำอะไรก็ทำ ชอบก็ทำ\nชีวิตมันมีค่ามากกว่านั้น\nชีวิตที่มีความสุข\nมันมีค่ามากกว่านั้น\n... ชีวิตมันไม่มีวันพัง\nชีวิตมันเริ่มต้นใหม่ได้เสมอ',
      signature: '/signature/veeraporn.png',
      by: 'วีรพร (อายุ 58 ปี),\nนักเขียนดับเบิ้ลซีไรต์',
    },
  ],
} as { [branch: string]: { quote: string; signature: string; by: string; smaller_for_iphone5?: boolean }[] }

export function Quote({ next }) {
  // const [state, setState] = React.useState({ branch: 'tale-maker', index: 0 })
  // const aQuote = quotes[state.branch][state.index]
  const story_branch = 'progressive-learner'
  const quoteArray = quotes[story_branch]
  const index = Math.floor(Math.random() * Math.floor(quoteArray.length))
  const aQuote = quoteArray[index]

  return (
    <>
      <div
        className={`font-bold text-center ${aQuote.smaller_for_iphone5 ? 'font-size-iphone5' : ''}`}
      >{`"${aQuote.quote}"`}</div>
      <div className="mt-2 px-2">
        <img className="ml-auto" style={{ height: '4rem' }} src={VichaOf} />
        <img className="ml-auto" style={{ height: '3.5rem' }} src={aQuote.signature} />
        <div className="text-xs text-right" style={{ fontSize: '0.7rem' }}>{`${aQuote.by}`}</div>
      </div>
      <div className="text-center mt-3">
        <button className="text-center items-center focus:outline-none" style={{ bottom: '4rem' }} onClick={next}>
          <span className="flex-no-wrap whitespace-no-wrap inline-flex text-orange">แตะเพื่อไปต่อ</span>
          <img className="inline-flex" style={{ height: '1.4em' }} src={nextIconOrange} />
        </button>
      </div>
      {/* <div className="fixed bottom-0 left-0 right-0">
        <select
          name="branch"
          id="branch"
          value={state.branch}
          onChange={(e) => {
            setState({ ...state, branch: e.target.value })
          }}
        >
          <option value="tale-maker">tale-maker</option>
          <option value="self-lover">self-lover</option>
          <option value="progressive-learner">progressive-learner</option>
          <option value="bitter-adult">bitter-adult</option>
          <option value="sad-adult">sad-adult</option>
        </select>
        <input
          type="number"
          name="index"
          value={state.index}
          onChange={(e) => {
            const val = parseInt(e.target.value)
            if (-1 < val && val < quotes[state.branch].length) {
              setState({ ...state, index: val })
            }
          }}
        />
      </div> */}
    </>
  )
}
