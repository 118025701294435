import * as React from 'react'
import nextIconOrange from '../../assets/logo/next-orange-icon.png'

const Message = ({ message }: { message: string }) => {
  return <div className="_message text-center">{message}</div>
}

export function Warning({ next }): JSX.Element {
  return (
    <>
      <Message
        message={`คำเตือน\nเว็บนี้มีเนื้อหาเกี่ยวกับความรู้สึก\nและความทรงจำที่คุณมีในวัยเด็ก\nหากคุณมีสภาวะจิตใจที่ไม่มั่นคง\nยังรับมือกับสภาวะทางอารมณ์ลบ\nของตนเองได้ไม่ดี\nหรือมีความทรงจำเกี่ยวกับการเติบโต\nหรือเกี่ยวกับบ้านที่ไม่อยากนึกถึง`}
      />
      <br />
      <Message message={`โปรดพิจารณาความเสี่ยงก่อนลงมือทำแบบทดสอบ`} />
      <br />
      <Message
        message={`หากคุณรู้สึกไม่สบายใจระหว่างการเล่น\nสามารถหยุดเล่นได้ตลอดเลยนะ\nการดูแลใจตัวเองสำคัญกว่าอะไรทั้งสิ้น`}
      />
      <br />
      <div className="text-xs">
        <Message
          message={`*เนื้อหาในเว็บนี้เป็นผลงาน\nการออกแบบการสื่อสารเพื่อเล่าเรื่องราว\nผ่านการให้ผู้เล่นมีส่วนร่วมด้วย\nไม่ใช่แบบสอบถามทางจิตวิทยา\nหรือโหราศาสตร์แต่อย่างใด`}
        />
      </div>
      <div className="text-center mt-3">
        <button className="text-center items-center focus:outline-none" style={{ bottom: '4rem' }} onClick={next}>
          <span className="flex-no-wrap whitespace-no-wrap inline-flex text-orange">แตะเพื่อไปต่อ</span>
          <img className="inline-flex" style={{ height: '1.4em' }} src={nextIconOrange} />
        </button>
      </div>
    </>
  )
}
