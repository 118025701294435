export type FlowComponent = {
  type: 'component'
}

export type FlowMessage = FlowComponent & {
  component: 'Message'
  message: string
}

export type FlowButtons = FlowComponent & {
  component: 'Buttons'
  message: string
  field?: string
  choice: {
    label: string
    value: string | number | boolean
    redirect?: string
  }[]
}

export type FlowInput = FlowComponent & {
  component: 'Input'
  field?: string
  message: string
}

export type FlowTextArea = FlowComponent & {
  component: 'Textarea'
  field?: string
  message: string
}

export type FlowInputForm = FlowComponent & {
  component: 'InputForm'
  value: {
    label: string
    element: string
    field: string
    choice?: {
      label: string
      value: any
    }[]
  }
}

export type FlowReference = {
  type: 'reference'
  id: string
}

export type FlowFunction = {
  type: 'function'
  function: string
  args: any[]
}

export type FlowRedirect = {
  type: 'redirect'
  redirect: string
  if?: string
}

export type FlowSpecificComponent = FlowComponent & {
  component: string
}

export type FlowComponentType =
  | FlowMessage
  | FlowButtons
  | FlowInput
  | FlowTextArea
  | FlowInputForm
  | FlowSpecificComponent

export type FlowLogicType = FlowReference | FlowFunction | FlowRedirect

export type Flow = FlowComponentType | FlowLogicType

export const NOOP_FLOW_COMPONENT: FlowSpecificComponent = {
  type: 'component',
  component: 'Noop',
}
