import React, { forwardRef, useCallback } from 'react'
import { useRef, useImperativeHandle, useEffect } from 'react'

// import * as PIXI from '../store/pixi' //! fail tree shaking with type declare
import * as PIXI from 'pixi.js'

import { values, mapValues, flatten, once, noop } from 'lodash'
import { gsap } from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin'
import screen from '../screen.json'
import { BehaviorSubject } from 'rxjs'
import { useRx } from '../util/useRx'

gsap.registerPlugin(PixiPlugin)
PixiPlugin.registerPIXI(PIXI)

const loader = PIXI.Loader.shared
const pixi_loader_progress$ = new BehaviorSubject(0)
loader.onError.add((...args) => console.error('fail load resource', args))
loader.onProgress.add(() => pixi_loader_progress$.next(loader.progress))
const pixi_loader_completed = new Promise<never>((resolve) =>
  loader.onComplete.add(() => {
    console.log('PIXI Loaded all resources successfully')
    resolve()
  })
)
loader.add(flatten(values(screen)))
loader.load()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function transparent_paper() {
  const root = document.documentElement
  root.style.setProperty('--paper-background', 'transparent')
}

function animate_sprite_gsap(
  sprite: PIXI.AnimatedSprite,
  { alpha = 1, duration = 1, loop = true, animationSpeed = 1 / 35 }: Omit<ScreenPlayProp, 'screen'>
) {
  console.log(`%csprite = ${sprite.name} (${alpha == 1 ? 'on' : 'off'})`, 'color: #45bae2')
  if (animationSpeed) {
    sprite.animationSpeed = animationSpeed
  }
  if (loop) {
    sprite.loop = true
    return new Promise((res) => {
      gsap.to(sprite, { duration, pixi: { alpha }, onComplete: () => res() })
    })
  } else {
    sprite.loop = false
    sprite.alpha = 1
    sprite.gotoAndPlay(0)
    sprite.onFrameChange = () => console.log(`frame change [${sprite.name}] ${sprite.currentFrame}`)
    return new Promise((resolve) => {
      sprite.onComplete = () => {
        console.log('complete non repeat sprite', sprite.name)
        sprite.onFrameChange = noop
        sprite.onComplete = noop
        sprite.alpha = 0
        resolve()
      }
    })
  }
}

const SCREEN_WIDTH = 1200
const SCREEN_HEIGHT = 2400

function create_naruto_sprites() {
  return mapValues(screen, (urls, key) => {
    const sprite = PIXI.AnimatedSprite.fromImages(urls)
    sprite.position.set(0, 0)
    sprite.anchor.set(0.5)
    sprite.animationSpeed = 1 / 35
    sprite.alpha = 0
    sprite.autoUpdate = true
    sprite.play()
    sprite.name = key
    sprite.width = SCREEN_WIDTH
    sprite.height = SCREEN_HEIGHT
    return sprite
  })
}

export type ScreenPlayProp = {
  screen: string
  alpha?: number
  duration?: number
  loop?: boolean // -1 for no repeat
  async?: boolean
  animationSpeed?: number
}
export type PixiHandles = {
  play: (param: ScreenPlayProp) => Promise<any>
  initialize: () => void
  resize: () => void
  completed: Promise<never>
}

export const Pixi = forwardRef<PixiHandles, any>(function __PixiComponent(props, ref) {
  const canvas = useRef<HTMLCanvasElement>(null)
  const app = useRef<PIXI.Application>(null as any)
  const pixi_sprites = useRef<ReturnType<typeof create_naruto_sprites>>(null as any)
  const container = useRef(new PIXI.Container())
  const pixi_loader_progress = useRx(pixi_loader_progress$)

  function resize_trigger() {
    app.current.resize()
    container.current.position.set(app.current.view.width / 2, app.current.view.height / 2)
    container.current.scale.set(app.current.view.width / SCREEN_WIDTH)
  }

  const initialize = useCallback(
    once(() => {
      console.log('call init pixi')
      pixi_sprites.current = create_naruto_sprites()
      container.current.addChild(...values(pixi_sprites.current))
      app.current.stage.addChild(container.current)
    }),
    []
  )

  useImperativeHandle<PixiHandles, PixiHandles>(ref, () => ({
    play: async (param: ScreenPlayProp) => {
      await pixi_loader_completed
      initialize()
      resize_trigger()
      return await animate_sprite_gsap(pixi_sprites.current[param.screen], param)
    },
    initialize: initialize,
    resize: resize_trigger,
    completed: pixi_loader_completed,
  }))

  useEffect(() => {
    const root = canvas.current?.parentElement
    if (!root) {
      throw new Error('na parent to resize to')
    }
    app.current = new PIXI.Application({
      antialias: true,
      clearBeforeRender: true,
      view: canvas.current ?? undefined,
      backgroundColor: 0xffffff,
      // transparent: true,
      autoDensity: true,
      resizeTo: root,
    })
    // initialize() COMMENT This out because it is called before resources are all loaded, after resources are loaded initialize will be called automatically, so I think there is no problem for not calling this
    setInterval(() => {
      resize_trigger()
    }, 1000 / 60)
  }, [])
  return (
    <>
      <div id="pixi-wrapper" className="absolute w-full h-full top-0 left-0 pointer-events-none" style={{ zIndex: -1 }}>
        <div className={'text-center absolute m-5 ' + (100 - pixi_loader_progress < 1e-6 ? 'hidden' : '')}>
          Loading... {Math.round(pixi_loader_progress * 100) / 100}% <br />
          กำลังรวบรวมทรัพยากรสำหรับแสดงผลเว็บไซต์
          <br />
          อาจใช้เวลานานเนื่องจากมีข้อมูลเยอะมาก
          <br />
          กรุณารอสักครู่นะครับ :)
        </div>
        <canvas ref={canvas}></canvas>
      </div>
    </>
  )
})
