/* eslint-disable react/prop-types */
import * as React from 'react'
import { BaseForm } from './BaseForm'
import { storage_set, naruto_storage } from '../../store/localstorage'
import textareaBg from '../../assets/logo/textarea/textarea_orange.png'

export function Textarea({ next, message, placeholder, field, ...rest }) {
  const message_el = React.useRef<HTMLDivElement>(null as any)

  if (field) {
    rest.value = naruto_storage()[field] ?? ''
    rest.onChange = (e) => storage_set(field, e.target.value)
  }
  return (
    <>
      <BaseForm next={next}>
        {message && (
          <div ref={message_el} className="text-center mb-4">
            {message}
          </div>
        )}
        <div
          className="textarea-wrapper"
          style={{
            background: `url(${textareaBg}) 100% 100% no-repeat`,
            backgroundSize: '100% 100%',
            minWidth: '220px',
            width: message_el.current ? `${10 * (message_el.current.clientWidth / 10)}px` : '220px',
          }}
        >
          <textarea
            className="input w-full whitespace-pre-wrap" // If all the website style crash just remove prewrap, I added in hope to preserve newline
            style={{
              border: '0px',
              outline: 'none',
              background: 'transparent',
              marginTop: '0.5rem',
              // height: '65px',
              width: message_el.current ? `${10 * (message_el.current.clientWidth / 10)}px` : '220px',
              // paddingLeft: '5px',
            }}
            required
            placeholder={placeholder || 'พิมพ์คำตอบของคุณที่นี่'}
            {...rest}
          />
        </div>
      </BaseForm>
    </>
  )
}
