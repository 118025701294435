/* eslint-disable react/prop-types */
import * as React from 'react'
import { naruto_storage } from '../../store/localstorage'
import { BaseForm } from './BaseForm'
import { Input } from './InputForm'

export function LessonCategory({ next, field }) {
  const storage = naruto_storage()
  const lifeLesson = storage.life_lesson

  return (
    <BaseForm next={next}>
      <div className="text-xl font-bold px-4 paragraph">{`"${lifeLesson}"`}</div>
      <div className="mt-4 text-center">{`คุณคิดว่าบทเรียนชีวิตนี้เป็นบทเรียนวิชาอะไร`}</div>
      <div className="px-12">
        <Input
          label=""
          element="radio"
          field={field}
          choice={[
            { label: 'วิชามิตรภาพและความรัก', value: 'friendship-and-love' },
            { label: 'วิชาความสัมพันธ์ในบ้าน', value: 'family' },
            { label: 'วิชาที่ได้เรียนรู้จากคนอื่น', value: 'people-around-you' },
            { label: 'วิชาจากความผิดพลาด', value: 'mistakes-and-failures' },
            { label: 'วิชาพึ่งพาตนเอง', value: 'self-reliance' },
          ]}
        />
      </div>
    </BaseForm>
  )
}
