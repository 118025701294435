/* eslint-disable prettier/prettier */
import { isString, map, filter, identity, findIndex } from 'lodash'

import type { Flow } from './flow.types'
import intro_flows from '../story/intro.json'
import progressive_learner_flows from '../story/progressive-learner.json'
import endline_flows from '../story/endline.json'

export const ALL_FLOWS = [
  ...processJson(intro_flows),
  ...processJson(progressive_learner_flows),
  ...processJson(endline_flows),
]
export const ALL_FLOWS_ID: string[] = filter(map(ALL_FLOWS, 'id'), identity)

export function findFlowIndexById(id: string): number {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return findIndex(ALL_FLOWS, { id })!
}

function processJson(flows): Flow[] {
  return flows.map((itm) => {
    if (isString(itm)) {
      return { type: 'component', component: 'Message', message: itm }
    } else {
      if (itm.choice) {
        itm.component = 'Buttons'
        itm.choice = itm.choice.map((c: string | { label: string; value: string }) =>
          isString(c) ? { label: c, value: c } : c
        )
      }
      if (itm.redirect) itm.type = 'redirect'
      if (itm.id) itm.type = 'reference'
      if (itm.function) {
        itm.type = 'function'
        itm.args = itm.args ?? []
      }
      if (!itm.type) itm.type = 'component'
      return itm
    }
  })
}
