import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { naruto_storage } from '../store/localstorage'

import logo from '../assets/logo/life_lesson_green.png'

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
})

const THEME = createMuiTheme({
  palette: {
    type: 'dark',
  },
})

const introFields = {
  // Fields in intro
  name: 'ชื่อ',
  age: 'อายุ',
  gender: 'เพศ',
  scholar: 'ประเภทของผู้เล่น',
  save_answers: 'บันทึกคำตอบเพื่อส่งให้ภายหลัง',
  join_competition: 'สนใจร่วมการประกวด',
  eef_name: 'ชื่อ-นามสกุล',
  eef_school: 'โรงเรียน',
  eef_phone: 'เบอร์โทรศัพท์',
  music: 'เพลง',
  house_sentiment: 'ความรู้สึกเมื่อคิดถึงบ้านหลังนี้',
  remember_childhood_hobby: 'ยังจําได้มั้ยว่าสิ่งที่คุณชอบทําในวัยเด็กคืออะไร',
  childhood_hobby: 'สิ่งที่คุณชอบทําในวัยเด็ก',
  how_have_you_been: 'ชีวิตช่วงนี้เป็นยังไงบ้าง',
  have_you_done_what_you_dreamed: 'สิ่งที่เธออยากทำตอนเด็กตอนนี้เธอได้ทำมันหรือยัง',
  do_you_still_like_childhood_hobby: 'ยังชอบสิ่งที่ทำในวัยเด็กอยู่หรือเปล่า',
  do_you_like_yourself_now: 'เธอชอบตัวเองตอนนี้มั้ย',
  like_present_or_younger: 'เธอชอบตัวเองตอนนี้หรือตอนเด็กมากกว่ากัน',
}

const progressiveLearnerFields = {
  child_age: 'อายุของเด็ก',
  what_type_of_grownup: 'เล่าให้เขาฟังที\nว่าตอนนี้คุณกลาย\nเป็นผู้ใหญ่แบบไหนแล้ว',
  hard_to_grow_up: 'มันยากมั้ยนะ การโตขึ้น',
  what_have_you_been_through: 'กว่าจะโตมาได้ขนาดนี้\nคุณต้องผ่านอะไรมาบ้าง',
  fortunate_moment: 'เล่าให้เขาฟังที\nว่ามันจะมีเรื่องอะไรดี ๆ\nที่รอให้เขาไปเจอในอนาคตบ้าง',
  cheerful_message: 'ช่วยให้กําลังใจเด็กคนนี้\nในการโตขึ้นหน่อย',
  tiring_growing_up: 'เหนื่อยมั้ย การโตขึ้น',
  grateful_thing: 'มีอะไรที่อยากขอบคุณ\nหรือขอโทษตัวเอง\nจากการเติบโตที่ผ่านมามั้ย',
}

const sadAdultFields = {
  child_age: 'อายุของเด็ก',
  what_type_of_grownup: 'เล่าให้เขาฟังที\nว่าตอนนี้คุณกลาย\nเป็นผู้ใหญ่แบบไหนแล้ว',
  hard_to_grow_up: 'มันยากมั้ยนะ การโตขึ้น',
  what_have_you_been_through: 'กว่าจะโตได้ขนาดนี้\nคุณต้องผ่านอะไรมาบ้างนะ',
  grateful_thing: 'มีอะไรที่คุณอยากขอบคุณ\nหรือขอโทษตัวเอง\nจากการเติบโตที่ผ่านมามั้ย',
  could_you_cheer_up: 'ช่วยให้กําลังใจ\nเด็กคนนี้หน่อยได้มั้ย',
  cheerful_message: 'ช่วยให้กําลังใจเด็กคนนี้\nในการโตขึ้นหน่อย',
  tiring_growing_up: 'พี่เหนื่อยมั้ยการโตขึ้น',
  want_to_tell: 'อยากระบายหรือเปล่า',
  ventilation: 'สิ่งที่อยากจะระบาย',
}

const selfLoverFields = {
  child_age: 'อายุของเด็ก',
  hard_to_grow_up: 'มันยากมั้ยนะ การโตขึ้น',
  what_have_you_been_through: 'บอกเขาทีว่ากว่าจะเป็นคุณในวันนี้ได้\nต้องผ่านอะไรมาบ้าง',
  fortunate_moment: 'เล่าให้เขาฟังที\nว่ามันจะมีเรื่องอะไรดี ๆ\nที่รอให้เขาไปเจอในอนาคตบ้าง',
  cheerful_message: 'ช่วยให้กําลังใจเด็กคนนี้\nในการโตขึ้นหน่อย',
  tiring_growing_up: 'เหนื่อยมั้ย การโตขึ้น',
  grateful_thing: 'มีอะไรที่อยากขอบคุณตัวเอง\nจากการเติบโตที่ผ่านมามั้ย',
}

const taleMakerFields = {
  child_age: 'อายุของเด็ก',
  hard_to_grow_up: 'มันยากมั้ยนะ การโตขึ้น',
  turning_point: 'ช่วยเล่าเหตุการณ์\nที่เป็นจุดเปลี่ยนของคุณ\nให้เขาฟังหน่อย',
  turning_point_feeling: 'บอกตัวคุณตอนเด็กทีว่า\nถ้าเขาผ่านเหตุการณ์นั้นมาได้แล้ว\nเขาจะรู้สึกยังไงบ้าง',
  grateful_thing: 'มีอะไรที่คุณอยากขอบคุณตัวเอง\nจากการเติบโตที่ผ่านมามั้ย',
  cheerful_message: 'ช่วยให้กําลังใจ\nเด็กคนนี้ในการโตขึ้นหน่อย',
  tiring_growing_up: 'เหนื่อยมั้ย การโตขึ้น',
}

const bitterAdultFields = {
  child_age: 'อายุของเด็ก',
  what_you_like_about_this_child: 'สิ่งที่คุณชอบ\nในตัวเด็กคนนี้คืออะไร',
  what_type_of_grownup: 'เล่าให้เขาฟังที\nว่าตอนนี้คุณโตมา\nเป็นผู้ใหญ่แบบไหนแล้ว',
  hard_to_grow_up: 'มันยากมั้ยนะ การโตขึ้น',
  what_have_you_been_through: 'กว่าจะโตมาได้ขนาดนี้\nคุณต้องผ่านอะไรมาบ้างนะ',
  grateful_thing: 'มีอะไรที่คุณอยากขอบคุณ\nหรือขอโทษตัวเอง\nจากการเติบโตที่ผ่านมามั้ย',
  fortunate_moment: 'เล่าให้เขาฟังที\nว่ามันจะมีเรื่องอะไรดี ๆ\nที่รอให้เขาไปเจอในอนาคตบ้าง',
  cheerful_message: 'ช่วยให้กําลังใจ\nเด็กคนนี้ในการโตขึ้นหน่อย',
  tiring_growing_up: 'เหนื่อยมั้ย',
}

const endlineFields = {
  life_lesson: 'ชีวิตที่ผ่านมา\nสอนให้คุณรู้อะไรบ้างเหรอ',
}

const storybranchtoFields = {
  'progressive-learner': progressiveLearnerFields,
  'sad-adult': sadAdultFields,
  'self-lover': selfLoverFields,
  'tale-maker': taleMakerFields,
  'bitter-adult': bitterAdultFields,
}

export function SaveAnswers() {
  const history = useHistory()
  const classes = useStyles()
  const answers = naruto_storage() as { [name: string]: string | number | undefined }
  const introAnswers = Object.entries(introFields).map(([field, translation]) => ({
    key: translation,
    value: answers[field],
  }))
  const storyFields = (answers.story_branch && answers.story_branch in storybranchtoFields
    ? storybranchtoFields[answers.story_branch]
    : {}) as { [name: string]: string }
  const storyAnswers = Object.entries(storyFields).map(([field, translation]) => ({
    key: translation,
    value: answers[field],
  }))
  const endlineAnswers = Object.entries(endlineFields).map(([field, translation]) => ({
    key: translation,
    value: answers[field],
  }))

  const answerKeys = introAnswers.concat(storyAnswers, endlineAnswers)
  return (
    <MuiThemeProvider theme={THEME}>
      <img src={logo} style={{ margin: '0 auto', maxWidth: 256 }} />
      <div className="px-5 md:px-48">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>คำถาม</TableCell>
                <TableCell align="right">คำตอบ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answerKeys.map(
                (row) =>
                  row.value && (
                    <TableRow key={row.key}>
                      <TableCell>{row.key}</TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="flex justify-center items-center my-2 gap-3">
        <Button variant="outlined" onClick={() => history.push('/?index=endline_card')}>
          Back
        </Button>
        <Button variant="outlined" color="primary" onClick={window.print}>
          Save
        </Button>
      </div>
    </MuiThemeProvider>
  )
}
