import * as React from 'react'
import { useEffect } from 'react'
import logo from '../../assets/logo/collaboration.png'

export function LogoScreen({ next }) {
  useEffect(() => {
    const timer = setTimeout(next, 2000)
    return () => clearTimeout(timer)
  })

  return (
    <div>
      <img src={logo} style={{ maxWidth: '250px' }} />
    </div>
  )
}
