/* eslint-disable react/prop-types */
import * as React from 'react'
import { BaseForm } from './BaseForm'
import { naruto_storage, storage_set } from '../../store/localstorage'

// eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
export function Input({ next, message, field, ...rest }) {
  const style = { color: rest.color || 'black' }
  if (field) {
    rest.value = naruto_storage()[field] ?? ''
    rest.onChange = (e) => storage_set(field, e.target.value)
  }
  return (
    <>
      <BaseForm next={next} color={rest.color}>
        {message && (
          <div className="mb-3 text-center" style={style}>
            {message}
          </div>
        )}
        <input className="input w-full" required placeholder="พิมพ์คำตอบของคุณที่นี่" {...rest} />
      </BaseForm>
    </>
  )
}
