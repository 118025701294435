import * as React from 'react'
import { useEffect } from 'react'

export function PleaseShare({ next }: { next: (id?: string) => any }) {
  useEffect(() => {
    const timer = setTimeout(next, 7000)
    return () => clearTimeout(timer)
  })
  return (
    <div className="text-center text-sm">
      ขอบคุณที่มาร่วมการเดินทาง
      <br />
      ย้อนดูการเติบโตกับเรา
      <br />
      ถ้าชอบ มีข้อคิดเห็น
      <br />
      หรืออยากร่วมแบ่งปันข้อคิดจากการเติบโต
      <br />
      สามารถเข้ามาร่วมแชร์กันได้ที่
      <br />
      <a
        className="text-green-400 active:text-green-400 visited:text-green-400"
        href="https://twitter.com/search?q=%23ชีวิตสอนให้รู้ว่า"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>#ชีวิตสอนให้รู้ว่า</strong>
      </a>
      <br />
    </div>
  )
}
