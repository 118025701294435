import React, { useRef, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'

export interface AudioHandles {
  play: (source: string) => void
  pause: () => void
  source: () => string
}

export const Audio = forwardRef<AudioHandles, any>(function _Audio(props, ref) {
  const audio_el = useRef<HTMLAudioElement>(null as any)
  const audio_controler = useCallback(
    (source: string | null) => {
      if (source) {
        const url = `/music/${source}`
        audio_el.current.src = url
        console.log(`%cplay audio = ${url} (play)`, 'color: #F3F279')
        audio_el.current.play()
      } else {
        console.log(`%cplay audio = (stop)`, 'color: #F3F279')
        audio_el.current.pause()
      }
    },
    [audio_el]
  )
  const onEnded = () => {
    audio_el.current.play()
  }

  useEffect(() => {
    audio_el.current.addEventListener('ended', onEnded, false)
    return () => audio_el.current.removeEventListener('ended', onEnded)
  }, [])

  useImperativeHandle(ref, () => ({
    play(source: string | null) {
      audio_controler(source)
    },
    pause() {
      audio_controler(null)
    },
    source() {
      return audio_el.current.src
    },
  }))

  return (
    <audio id="player" ref={audio_el} loop preload="auto">
      <source src="/music/house.mp3" />
      <source src="/music/inspire.mp3" />
      <source src="/music/rain_noise.mp3" />
      <source src="/music/sky_noise.mp3" />
      <source src="/music/sun_noise.mp3" />
      Your browser does not support the <code>audio</code> element.
    </audio>
  )
})
