/* eslint-disable react/prop-types */
import * as React from 'react'
import { createElement } from 'react'
import { BaseForm } from './BaseForm'
import { storage_set, naruto_storage } from '../../store/localstorage'
import { MultiSelectDropdown } from './MultiSelectDropdown'
import logo from '../../assets/logo/life_lesson_green.png'

export function Input({ field, element, label, ...rest }) {
  function onChange(e: React.ChangeEvent<any>) {
    if (field) {
      const value = e.target.value
      storage_set(field, value)
    }
  }

  const inside_element: JSX.Element = (() => {
    const storage = naruto_storage()
    const props = {
      className: 'input block',
      onChange,
      required: true,
      placeholder: 'พิมพ์เพื่อตอบ',
      name: field,
      value: field ? naruto_storage()[field] : '',
      ...rest,
    }
    switch (element) {
      case 'input':
      case 'textarea':
        return createElement(element, props)
      case 'select':
        return createElement(
          element,
          props,
          rest.choice.map((c) => (
            <option value={c.value} key={c.value}>
              {c.label}
            </option>
          ))
        )
      case 'multiselect':
        return <MultiSelectDropdown field={field} />
      case 'logo':
        return <img src={logo} style={{ margin: '0 auto', maxWidth: '45%' }} />
      case 'radio':
        return (
          <>
            {rest.choice.map((c) => (
              <label className="block" key={field + c.value}>
                <input type="radio" {...props} className="mr-3" value={c.value} checked={storage[field] === c.value} />
                {c.label}
              </label>
            ))}
          </>
        )
      case 'center-label':
        return <div className="mt-2 mb-1 left text-center">{rest.msg}</div>
      case 'center-label-dangerous':
        return <div className="mt-2 mb-1 left text-center" dangerouslySetInnerHTML={{ __html: rest.msg }} />
      default:
        return <div>Error no {element} element</div>
    }
  })()

  return (
    <>
      {label && <div className="mt-2 mb-1 left">{label}</div>}
      {inside_element}
    </>
  )
}

export function InputForm({ next, value }) {
  return (
    <BaseForm
      next={() => {
        const storage = naruto_storage()
        const { name, age, gender, scholar, save_answers } = storage
        if (name && age && gender && scholar && save_answers) {
          next()
        }
      }}
    >
      {value.map((c, i) => (
        <Input {...c} key={i} />
      ))}
    </BaseForm>
  )
}
