import { find } from 'lodash'

import * as React from 'react'
import { useEffect, useState } from 'react'
// import htmlToImage from 'html-to-image'
import * as qs from 'qs'
import { naruto_storage, useLocalStorage } from '../../store/localstorage'

import Paper from '@material-ui/core/Paper'
import FacebookIcon from '@material-ui/icons/Facebook'
import SaveIcon from '@material-ui/icons/Save'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

import HashLoader from 'react-spinners/HashLoader'

import Share from '../../assets/icon/share.png'
import WhiteLogo from '../../assets/logo/life_lesson_white.png'
import ShareToFacebook from '../../assets/icon/share-fb.png'

import { api } from '../../store/api'

function get_card_url(branch: string) {
  const choice = [
    { branch: 'tale-maker', url: '/card/tale-maker.png' },
    { branch: 'self-lover', url: '/card/self-lover.png' },
    { branch: 'progressive-learner', url: '/card/progressive-learner.png' },
    { branch: 'sad-adult', url: '/card/sad-adult.png' },
    { branch: 'bitter-adult', url: '/card/bitter-adult.png' },
  ]
  const result = find<typeof choice[0]>(choice, { branch })
  return result ? result.url : '/card/progressive-learner.png'
}

function get_thumbnail(branch: string) {
  const choice = [
    { branch: 'tale-maker', thumbnail: '/thumbnail/tale-maker.jpg' },
    { branch: 'self-lover', thumbnail: '/thumbnail/self-lover.jpg' },
    { branch: 'progressive-learner', thumbnail: '/thumbnail/progressive-learner.jpg' },
    { branch: 'sad-adult', thumbnail: '/thumbnail/sad-adult.jpg' },
    { branch: 'bitter-adult', thumbnail: '/thumbnail/bitter-adult.jpg' },
  ]
  const result = find<typeof choice[0]>(choice, { branch })
  return result ? result.thumbnail : '/thumbnail/progressive-learner.jpg'
}

type CardState = { loaded: boolean; url: string }

export function Poster({ next }: { next: (id?: string) => any }) {
  const [paperWidth, setPaperWidth] = useState(400)
  const [thumbnailState, setThumbnailState] = useState<CardState>({
    loaded: false,
    url: '',
  })
  const [menuVisible, setMenuVisible] = useState(false)
  const [shareFacebookUrl, setShareFacebookUrl] = useState('')
  const [uploadingToSpace, setUploadingToSpace] = useState(false)
  useEffect(() => {
    setPaperWidth(document.getElementById('paper')?.clientWidth || 400)
    alert(
      'อ่า พี่ป่าน... คือผมคิดว่าพี่อาจจะไม่ได้ยินเสียง video 5555555\nลองคลิกขวาที่วีดีโอแล้วกด Show Video in new Tab หรือ Save Video แล้วค่อยเอาไปดูแยกก็ไดฮะ'
    )
  }, [])

  const generateThumbnail = () => {
    const ctx = canvasThumbnailRef.current.getContext('2d')
    const imageObj1 = new Image()
    imageObj1.src = get_thumbnail(storage.story_branch) || ''
    console.log(imageObj1)
    if (ctx) {
      imageObj1.onload = () => {
        const imgWidth = THUMBNAIL_WIDTH
        const imgHeight = THUMBNAIL_HEIGHT
        ctx.canvas.width = imgWidth
        ctx.canvas.height = imgHeight
        ctx.drawImage(imageObj1, 0, 0, imgWidth, imgHeight)

        ctx.font = `bold ${80}px Wittaya`
        const textString = name || 'ตัวของฉัน'
        const textWidth = ctx.measureText(textString).width
        ctx.fillStyle = '#68d391'
        ctx.fillText(textString, THUMBNAIL_WIDTH - textWidth - 59, 370)

        setThumbnailState({ ...thumbnailState, loaded: true })
      }
    }
  }

  const storage = useLocalStorage()
  const card_url = get_card_url(storage.story_branch)
  const { life_lesson, name } = naruto_storage()

  const canvasThumbnailRef = React.useRef<HTMLCanvasElement>(null as any)

  const CARD_RENDER_HEIGHT = 400
  const CARD_WIDTH = 1052
  const CARD_HEIGHT = 1725
  const THUMBNAIL_WIDTH = 1200
  const THUMBNAIL_HEIGHT = 628

  async function shareToFacebook() {
    alert('HBD ครับพี่ป่าน')
  }

  function hideMenu() {
    setMenuVisible(false)
  }

  return (
    <div>
      <canvas
        id="image-canvas"
        ref={canvasThumbnailRef}
        style={{
          maxWidth: THUMBNAIL_WIDTH,
          width: THUMBNAIL_WIDTH,
          height: THUMBNAIL_HEIGHT,
        }}
        className={`fixed invisible bottom-0 left-0 ${thumbnailState.loaded ? 'opacity-1' : 'opacity-0'}`}
      />
      <div
        className={`fixed flex justify-center items-center top-0 bottom-0 left-0 right-0 bg-gray-800 bg-opacity-75 z-10 transition duration-75 ${
          menuVisible ? 'visible' : 'invisible'
        }`}
        onClick={hideMenu}
      >
        <Paper style={{ maxWidth: paperWidth, minWidth: 250, minHeight: 250 }} className="mx-auto my-auto">
          <div
            className="flex flex-col h-full items-center justify-center text-center"
            style={{ maxWidth: paperWidth, minWidth: 250, minHeight: 250 }}
          >
            <div>
              <a href="/answers" className="text-green-400 active:text-green-400 visited:text-green-400 ">
                <SaveAltIcon /> ดูคำตอบของคุณ
              </a>
            </div>
            <div>
              {uploadingToSpace ? (
                <>
                  <HashLoader size={20} color={'#68d391'} /> กำลังสร้าง Thumbnail สำหรับการแชร์
                </>
              ) : (
                <a onClick={shareToFacebook} className="text-green-400 active:text-green-400 visited:text-green-400 ">
                  <FacebookIcon /> แชร์ผ่าน Facebook
                </a>
              )}
            </div>
            <div>
              <a href="/poster" className="text-green-400 active:text-green-400 visited:text-green-400 ">
                <SaveIcon /> บันทึกรูปของคุณ
              </a>
            </div>
          </div>
        </Paper>
      </div>
      <div className="text-center text-lg font-bold">ของที่ระลึกจากการกลับบ้าน</div>
      <div
        className="overflow-x-scroll overflow-y-hidden flex flex-no-wrap"
        style={{ maxHeight: CARD_RENDER_HEIGHT + 20, maxWidth: paperWidth }}
      >
        <video width="320" height="240" autoPlay loop>
          <source src="/hbd.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="flex justify-center mt-2 margin-top-share">
        <div className="flex flex-row">
          {uploadingToSpace ? (
            <>
              <HashLoader size={40} color={'#68d391'} /> กำลังสร้าง Thumbnail สำหรับการแชร์
            </>
          ) : (
            <button onClick={shareToFacebook} className="pointer-event-auto mx-1">
              <img src={ShareToFacebook} alt="Share To Facebook" className="h-8" />
            </button>
          )}
        </div>
      </div>
      <div className="text-xs font-size-share px-8 text-center">
        ร่วมแบ่งปันเรื่องราวของคุณผ่าน
        <br />
        <a
          className="font-bold text-black active:text-black visited:text-black"
          href="https://twitter.com/search?q=%23ชีวิตสอนให้รู้ว่า"
          target="_blank"
          rel="noopener noreferrer"
        >
          #ชีวิตสอนให้รู้ว่า
        </a>
        <br />
        และค้นพบเรื่องราวอีกมากมายในเว็บไซต์ของเรา
      </div>
      <div className="flex flex-row justify-center mt-2 margin-top-share">
        <button className="mx-1" onClick={() => setMenuVisible(true)}>
          <span>
            บันทึกคำตอบหรือการ์ดของคุณ <img src={Share} alt="Menu" className="h-6 inline-block" />
          </span>
        </button>
      </div>
      <div className="absolute right-0 left-0 bottom-0  text-center mx-auto flex flex-row bg-green-500 items-center h-10">
        <a
          className="flex-1 text-white active:text-white visited:text-white text-sm"
          href="https://vichacheewit.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          เข้าสู่เว็บไซต์
          <img src={WhiteLogo} alt="Vicha Cheewit" className="inline-block h-10 enter-website-height" />
        </a>
        <div className="flex-none bg-white" style={{ height: 30, width: 2 }} />
        <a
          className="flex-1 text-white active:text-white visited:text-white text-sm w-100"
          href="/about"
          target="_blank"
          rel="noopener noreferrer"
        >
          เกี่ยวกับเรา
        </a>
      </div>
    </div>
  )
}
