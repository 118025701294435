import React from 'react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import Fab from '@material-ui/core/Fab'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import GlowActive from '../assets/about/logo/glow-active.png'
import GlowInactive from '../assets/about/logo/glow-inactive.png'
import DeadlineActive from '../assets/about/logo/deadline-active.png'
import DeadlineInactive from '../assets/about/logo/deadline-inactive.png'
import EefActive from '../assets/about/logo/eef-active.png'
import EefInactive from '../assets/about/logo/eef-inactive.png'
import EefBg from '../assets/about/outline/eef-bg.png'
import DeadlineBg from '../assets/about/outline/deadline-bg.png'
import GlowBg from '../assets/about/outline/glow-bg.png'
import { useLocation } from 'react-router-dom'

const scrollToReference = (
  ref: React.MutableRefObject<HTMLDivElement>,
  bannerRef: React.MutableRefObject<HTMLDivElement>
) => {
  window.scrollTo({ top: ref.current.offsetTop - bannerRef.current.clientHeight, behavior: 'smooth' })
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const AboutUs = () => {
  return (
    <div className="bg-white">
      <div className="flex justify-center items-center">
        {/* <div
        id="about-us-head"
        className="flex"
        style={{
          backgroundImage: `url(${HeadCircle})`,
          width: '10rem',
          height: '7rem',
          backgroundSize: 'cover',
        }}
      > */}
        <div className="mx-auto my-10 font-bold text-l">เกี่ยวกับเรา</div>
        {/* </div> */}
      </div>
    </div>
  )
}

export function About() {
  const query = useQuery()

  const EEF = 'EEF'
  const DEADLINE = 'Deadline'
  const GLOW = 'Glow'

  const banner_el = React.useRef<HTMLDivElement>(null as any)
  const eef_el = React.useRef<HTMLDivElement>(null as any)
  const deadline_el = React.useRef<HTMLDivElement>(null as any)
  const glow_el = React.useRef<HTMLDivElement>(null as any)

  const [scrolled, setScrolled] = useState(false)

  const [bgColor, setBgColor] = useState('#ffffff')
  const [currentSection, setCurrentSection] = useState(EEF)
  const [eefInviewRef, eefInView, eefEntry] = useInView({
    threshold: 0.5,
  })
  const [deadlineInviewRef, deadlineInView, deadlineEntry] = useInView({
    threshold: 0.3,
  })
  const [glowInviewRef, glowInView, glowEntry] = useInView({
    threshold: 0.2,
  })

  useEffect(() => {
    const about = query.get('about')
    console.log('about', about)
    if (about) {
      switch (about) {
        case EEF:
          scrollToReference(eef_el, banner_el)
          break
        case DEADLINE:
          scrollToReference(deadline_el, banner_el)
          break
        case GLOW:
          scrollToReference(glow_el, banner_el)
          break
      }
    }
  }, [])

  useEffect(() => {
    if (glowInView) {
      enterGlow()
    } else if (deadlineInView) {
      setScrolled(true)
      enterDeadline()
    } else {
      enterEef()
    }
  }, [eefInView, deadlineInView, glowInView])

  const enterGlow = () => {
    setCurrentSection(GLOW)
    // setBgColor('#f2fcff')
  }

  const enterDeadline = () => {
    setCurrentSection(DEADLINE)
    // setBgColor('#fff9f2')
  }

  const enterEef = () => {
    setCurrentSection(EEF)
    // setBgColor('#f7fff8')
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      extendedIcon: {
        marginRight: theme.spacing(1),
      },
    })
  )
  const classes = useStyles()

  return (
    <div className="" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div
          className={
            'fixed bottom-0 w-full left-0 right-0 flex justify-center mb-2 transition duration-200 ease-in transform ' +
            (scrolled ? 'opacity-0 translate-y-10' : 'opacity-1 translate-y-0')
          }
        >
          <Fab color="primary" variant="extended" onClick={() => scrollToReference(deadline_el, banner_el)}>
            <ExpandLess className={classes.extendedIcon} />
            Scroll to see more
          </Fab>
        </div>
        <AboutUs />
        <div
          ref={banner_el}
          className="sticky top-0 flex justify-center items-center py-8 gap-8 sm:gap-10 md:gap-16 lg-20"
          style={{ backgroundImage: 'linear-gradient(180deg,#FFF, #FFFA)' }}
        >
          <a
            className="inline-block mx-1"
            onClick={() => {
              scrollToReference(eef_el, banner_el)
            }}
          >
            {currentSection === EEF ? (
              <img src={EefActive} alt="eef-active" className="mx-w-logo mx-auto" />
            ) : (
              <img src={EefInactive} alt="eef-inactive" className="mx-w-logo mx-auto" />
            )}
          </a>
          <a
            className="inline-block mx-1"
            onClick={() => {
              scrollToReference(deadline_el, banner_el)
            }}
          >
            {currentSection === DEADLINE ? (
              <img src={DeadlineActive} alt="deadline-active" className="mx-w-logo mx-auto" />
            ) : (
              <img src={DeadlineInactive} alt="deadline-inactive" className="mx-w-logo mx-auto" />
            )}
          </a>
          <a
            className="inline-block mx-1"
            onClick={() => {
              scrollToReference(glow_el, banner_el)
            }}
          >
            {currentSection === GLOW ? (
              <img src={GlowActive} alt="glow-active" className="mx-w-logo mx-auto" />
            ) : (
              <img src={GlowInactive} alt="glow-inactive" className="mx-w-logo mx-auto" />
            )}
          </a>
        </div>
        <div
          ref={eefInviewRef}
          className="px-16 sm:px-32 md:px-40 mb-56 text-teal-800"
          style={{
            backgroundImage: `url(${EefBg})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div ref={eef_el}>
            <p className="font-bold">เกี่ยวกับ กสศ.</p>
            <p className="paragraph">
              เด็ก เยาวชน และประชาชน ที่ขาดแคลนทุนทรัพย์หรือด้อยโอกาส ทุกคนมีโอกาสพัฒนาตนเองตามศักยภาพ
              และเข้าถึงการศึกษาที่มีคุณภาพ
            </p>
            <p className="paragraph">
              กสศ.หรือ กองทุนเพื่อความเสมอภาคทางการศึกษา เป็นหน่วยงานของรัฐซึ่งมีการบริหารงานที่เป็นอิสระ
              มีภารกิจในการช่วยเหลือดูแลกลุ่มเป้าหมายซึ่งเป็นผู้ขาดแคลนทุนทรัพย์
              หรือด้อยโอกาสนับตั้งแต่แรกเกิดจนถึงวัยแรงงานให้ได้รับโอกาสทางการศึกษา
              เพื่อบรรเทาความยากจนอันเป็นรากเหง้าของปัญหาอื่นๆ ซึ่งหากแก้ไม่ได้ ปัญหานี้จะส่งทอดวนเวียนไปข้ามชั่วคน
              จากพ่อแม่ ส่งต่อไปถึงรุ่นลูกรุ่นหลานได้เพราะมีกลุ่มคนที่เข้าไม่ถึงการศึกษา
              หรือได้รับการศึกษาที่มีคุณภาพต่างกัน โดยกสศ. เป็นองค์กรที่มีบทบาทในการพัฒนาตัวแบบ
              และองค์ความรู้ในเรื่องนี้
              เพื่อหน่วยงานหลักที่เกี่ยวข้องนำไปใช้ขยายผลซึ่งนำไปสู่ผลลัพธ์เปลี่ยนแปลงชีวิตเด็กและเยาวชนได้อย่างเป็นรูปธรรม
            </p>
          </div>
        </div>
        <div
          ref={deadlineInviewRef}
          className="px-16 sm:px-32 md:px-40 mb-56 text-orange-800"
          style={{
            backgroundImage: `url(${DeadlineBg})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div ref={deadline_el}>
            <p className="font-bold">เกี่ยวกับ Deadline Always Exists</p>
            <p className="paragraph">
              พวกเรากลุ่มเพื่อนหกคนที่เชื่อว่าไอเดียเปลี่ยนโลกได้จริงๆ รวมตัวกันเพื่อออกแบบงานสื่อสารเพื่อสังคม
            </p>
            <p className="paragraph">
              โปรเจคแรกของพวกเราคือ Deadline Always Exists โปรเจคที่นำหลักคิดแบบมรณานุสติ หรือการมีสติกับการมีชีวิต
              ใช้ชีวิตอย่างตระหนักรู้ว่าชีวิตนี้มีจำกัด และเรามีเพียงระหว่างที่มีชีวิตเท่านั้นที่สามารถมีชีวิตได้
              ผ่านการออกแบบการสื่อสารที่ทำให้ผู้รับสารสามารถมีส่วนร่วมกับการสื่อสารได้จริงๆ
            </p>
            <p className="paragraph">
              ภายหลังโปรเจคแรก คำถามที่ทิ้งไว้ให้พวกเราคือ แล้วตัวเราเองล่ะ ? อยากทำอะไรในขณะที่ยังมีชีวิต ?
            </p>
            <p className="paragraph">หลังจากคุยกันไม่นาน ความเห็นพวกเราก็ตรงกันอย่างไม่น่าเชื่อ</p>
            <p className="paragraph">พวกเราอยากเปลี่ยนโลกให้ได้มากที่สุดก่อนที่เราจะต้องตาย</p>
            <p className="paragraph">
              พวกเราหกขึ้นจึงรวมตัวกันก่อตั้งเพจ Inside the Sandbox
              ที่มุ่งมั่นสร้างงานออกแบบการสื่อสารเพื่อสร้างการเปลี่ยนแปลง โดยในแต่ละเดือน
              (หรือนานกว่านั้นหากจังหวะชีวิตยังไม่อนุญาต) เราจะเลือกปัญหาที่เราอยากแก้ แล้วปั้นชิ้นงานออกมาลองแก้มันดู
            </p>
            <p className="paragraph">
              ส่วนในระหว่างที่งานยังไม่ออกนั้น พวกเราจะหาไอเดียการเปลี่ยนแปลงใหม่ๆ มาเสิร์ฟบนหน้าไทม์ไลน์ให้คุณ
            </p>
            <p className="paragraph"></p>
            <p className="paragraph">
              ถ้าสนใจอยากลองเปลี่ยนโลก หรือโลกของใครสักคนนี้เหมือนกับเรา สามารถติดตามเราได้ที่{' '}
              <a href="https://www.facebook.com/InsidetheSandbox/" target="_blank" rel="noopener noreferrer">
                เพจ InsidetheSandbox
              </a>
            </p>
            <p>แล้วพบกันใหม่โปรเจคหน้าค่ะ :)</p>
          </div>
        </div>
        <div
          ref={glowInviewRef}
          className="px-16 sm:px-32 md:px-40 pb-56 text-indigo-800"
          style={{
            backgroundImage: `url(${GlowBg})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div ref={glow_el}>
            <p className="font-bold">เกี่ยวกับ Glow Story</p>
            <p className="paragraph">
              Glow Story ทีมงานคนรุ่นใหม่ที่เชื่อในการสร้างสรรค์ไอเดียเพื่อเปลี่ยนแปลงสังคม
              ผ่านการเล่าเรื่องในรูปแบบที่ต่างกันออกไป
            </p>
            <p className="paragraph">
              โดยให้ความสำคัญกับประสบการณ์และความต้องการของกลุ่มเป้าหมาย
              และมีความตั้งใจในการสร้างงานที่สอดคล้องสถานการณ์ปัจจุบัน
            </p>
            <p className="paragraph">ไม่ว่าจะบนรูปแบบออนไลน์หรือออฟไลน์</p>
          </div>
        </div>
      </div>
    </div>
  )
}
