import * as React from 'react'
import { once } from 'lodash'

type Props = {
  next: () => void
  message: string
  color?: string
}

export function Message({ next, message, color }: Props) {
  const style = { color: color || 'black' }
  return (
    <>
      <div
        className="click-area w-screen h-screen absolute top-0 left-0"
        style={{ zIndex: 1000 }}
        onClick={once(next)}
      />
      <div className="_message text-center" style={style}>
        {message}
      </div>
    </>
  )
}
