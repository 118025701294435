import * as React from 'react'
import { Switch, Route } from 'react-router-dom'

import { history } from '../store/history'
import { About } from './About'
import { Journey } from './Journey'
import { Policy } from './Policy'
import { SaveAnswers } from './SaveAnswers'
import { SaveCard } from './SaveCard'
import version from '../../auto-version.json'
console.log(`%cversion: ${JSON.stringify(version)}`, 'color: #F3F279')

export default function App(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/" component={Journey} />
      <Route path="/about" component={About} />
      <Route path="/policy" component={Policy} />
      <Route path="/answers" component={SaveAnswers} />
      <Route path="/poster" component={SaveCard} />
      <Route path="/*">
        <div>404 not found</div>
      </Route>
    </Switch>
  )
}
