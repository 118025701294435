import * as React from 'react'
import { BaseForm } from './BaseForm'
import logo from '../../assets/logo/life_lesson_green.png'

export function Introduction({ next }): JSX.Element {
  return (
    <div className="text-sm">
      <BaseForm next={() => next('intro-register')}>
        <img src={logo} style={{ margin: '0 auto', maxWidth: '45%' }} />
        <div className="_message text-center">{`งานน้ำสำหรับพี่ป่านโดยเฉพาะ`}</div>
        <div className="_message text-center italic">{`ใครไม่ใช่พี่ป่านออกไปนะ !!\n`}</div>
        <br />
      </BaseForm>
    </div>
  )
}
