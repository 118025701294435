/* eslint-disable @typescript-eslint/no-unused-vars */
'use strict'

import { get, find, escape, trim } from 'lodash'
import { useEffect, useState } from 'react'
import { Subject } from 'rxjs'

const LOCAL_STORAGE_FIELD = 'life-lesson'
const storage_trigger = new Subject<void>()

export function naruto_storage() {
  const raw = localStorage.getItem(LOCAL_STORAGE_FIELD)
  const obj = JSON.parse(raw || '{}')
  // auto calculate
  obj.hangseang = get({ หญิง: 'คะ', ชาย: 'ครับ' }, obj.gender, '')
  obj.pronoun = get({ หญิง: 'หนู', ชาย: 'ผม' }, obj.gender, 'เค้า')
  obj.could_you_cheer_up = get(obj, 'could_you_cheer_up', '')
  obj.story_branch = calculate_branch(obj.do_you_like_yourself_now, obj.like_present_or_younger)
  return obj
}

export function storage_set(field: string, value: string | number | boolean) {
  console.log(`%c${field} = ${value}`, 'color: #bada55')
  localStorage.setItem(LOCAL_STORAGE_FIELD, JSON.stringify({ ...naruto_storage(), [field]: value }))
  storage_trigger.next(naruto_storage())
}

export function storage_clear() {
  localStorage.setItem('life-lesson', '{}')
}

export function useLocalStorage() {
  const [ls, ls_set] = useState(naruto_storage())
  useEffect(() => {
    const sub = storage_trigger.subscribe((val) => ls_set(val))
    return () => sub.unsubscribe()
  }, [])
  return ls as { story_branch: string; [key: string]: any }
}

// str must be in JSON.stringify format
export function compile_label(str: string): string {
  const storage = naruto_storage()
  return (str || '{}').replace(/{{([^}]*)}}/g, (m, $1) => {
    if (!storage.hasOwnProperty($1)) {
      alert(`field ${$1} is not exit in localstorage. please check`)
      console.error(`field ${$1} is not exit in localstorage. please check`)
    }
    return escape(trim(storage[$1]))
  })
}

export function storage_eval(pred: string) {
  const {
    hangseang,
    pronoun,
    name,
    age,
    gender,
    scholar,
    save_answers,
    join_competition,
    how_long_having_time_for_yourself,
    music,
    how_long_since_last_visit,
    like_present_or_younger,
    remember_childhood_hobby,
    childhood_hobby,
    how_have_you_been,
    how_are_you,
    do_you_still_like_childhood_hobby,
    thing_on_shelve,
    do_you_like_yourself_now,
    child_age,
    what_have_you_been_through,
    grateful_thing,
    cheerful_message,
    life_lesson,
    could_you_cheer_up,
    allow_share,
  } = naruto_storage()
  try {
    return eval(pred)
  } catch (e) {
    console.error(e)
    // alert(e.message)
    return false
  }
}

function calculate_branch(do_you_like_yourself_now: string, like_present_or_younger: string) {
  // prettier-ignore
  const branchs = [
    { do_you_like_yourself_now: 'yes', like_present_or_younger: 'like_present', id: 'tale-maker' },
    { do_you_like_yourself_now: 'yes', like_present_or_younger: 'like_younger', id: 'self-lover' },
    { do_you_like_yourself_now: 'not_sure', like_present_or_younger: 'like_present', id: 'progressive-learner' },
    { do_you_like_yourself_now: 'not_sure', like_present_or_younger: 'like_younger', id: 'bitter-adult' },
    { do_you_like_yourself_now: 'no', like_present_or_younger: 'like_present', id: 'progressive-learner' },
    { do_you_like_yourself_now: 'no', like_present_or_younger: 'like_younger', id: 'sad-adult' },
  ];
  const query = {
    do_you_like_yourself_now,
    like_present_or_younger,
  }
  const branch = find<typeof branchs[0]>(branchs, query)
  return branch ? branch.id : null
}

export function get_story_branch(): 'tale-maker' | 'self-lover' | 'progressive-learner' | 'bitter-adult' | 'sad-adult' {
  return naruto_storage().story_branch
}
