/* eslint-disable react/prop-types */
import * as React from 'react'
import { naruto_storage } from '../../store/localstorage'
import { Textarea } from './Textarea'
import { Buttons } from './Buttons'
import { compact } from 'lodash'

export function AgeSelection({ next, field }) {
  const storage = naruto_storage()
  if (!storage.age) {
    alert('age is not set')
  }
  const age = parseInt(storage.age)
  if (isNaN(age)) {
    alert('age is NaN')
  }
  const intermediateAge = 12 <= age
  const adultAge = 15 <= age
  const matureAge = 18 <= age
  const choice = [
    'อนุบาล',
    'ประถม',
    intermediateAge && 'มัธยมต้น',
    adultAge && 'มัธยมปลาย / ปวช',
    matureAge && 'มหาวิทยาลัย / ปวส',
  ]
  return storage.scholar === 'none' ? (
    <Buttons
      {...{
        message: 'คุณคิดว่า เด็กคนนี้\nคือตัวคุณในช่วงอายุไหน',
        next,
        field,
        choice: compact(choice).map((v) => ({ label: v, value: v })),
      }}
    />
  ) : (
    <Textarea
      {...{
        message: 'คุณคิดว่า เด็กคนนี้\nคือคุณในช่วงวัยไหน',
        next,
        field,
        placeholder: 'ประมาณเอาก็ได้นะ',
      }}
    />
  )
}
