import * as React from 'react'
import { Message } from './Message'

export function WelcomeHome({ next }) {
  return (
    <div className="font-bold text-xl">
      <Message message="ยินดีต้อนรับกลับบ้านนะ" next={next} />
    </div>
  )
}
