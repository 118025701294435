import './styles/style.scss'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from './components/App'
import { history } from './store/history'

updateVisitCount()
function updateVisitCount() {
  // fetch('https://api.countapi.xyz/update/journey-vichacheewit/journey/?amount=1')
}

const ROOT = document.querySelector('#root')

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  ROOT
)
