import * as React from 'react'
import { useEffect } from 'react'

export function Empty({ next }) {
  useEffect(() => {
    const timer = setTimeout(next, 300)
    return () => clearTimeout(timer)
  })
  return <div />
}
