/* eslint-disable prettier/prettier */
import * as React from 'react'
import nextIconOrange from '../../assets/logo/next-orange-icon.png'
// import nextIconBlack from '../../assets/logo/next-black-icon.png'
import nextIconWhite from '../../assets/logo/next-white-icon.png'

type FragmentInputProps = {
  next: () => void
  children?: JSX.Element[]
  color?: string
}

export function BaseForm({ next, color, children }: FragmentInputProps) {
  const style = { color: color || 'black' }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        next()
      }}
    >
      <div>{children}</div>
      <div className="border-none mt-5 flex justify-end items-center">
        <button className="inline-flex focus:outline-none" type="submit">
          <span className="flex-no-wrap whitespace-no-wrap inline-flex" style={style}>
            ต่อไป
          </span>
          <img
            className="inline-flex"
            style={{ height: '1.4em' }}
            src={color === 'white' ? nextIconWhite : nextIconOrange}
          />
        </button>
      </div>
    </form>
  )
}
