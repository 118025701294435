import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'

export function useRx<T>(ob: BehaviorSubject<T>) {
  const [value, setValue] = useState(ob.value)
  useEffect(() => {
    const sub = ob.subscribe((val) => setValue(val))
    return () => sub.unsubscribe()
  }, [])
  return value
}
