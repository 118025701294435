import * as React from 'react'
import { storage_set } from '../../store/localstorage'

export function Buttons({ next, message, field, choice, ...rest }) {
  const style = { color: rest.color || 'black' }
  const styleButton = { color: rest.color === 'white' ? '#ff7003' : 'black' }
  return (
    <>
      <div className="mb-6 text-center" style={style}>
        {message}
      </div>
      {choice.map((c) => (
        <button
          key={c.label}
          className="button block w-full mb-2"
          style={{ width: '100%', ...styleButton }}
          value={c.value}
          onClick={() => {
            storage_set(field, c.value)
            next(c.redirect)
          }}
        >
          {c.label}
        </button>
      ))}
    </>
  )
}
