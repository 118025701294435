import * as React from 'react'
import { useEffect, useState } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { find } from 'lodash'
import { useLocalStorage } from '../store/localstorage'

function get_card_url(branch: string) {
  const choice = [
    { branch: 'tale-maker', url: '/card/tale-maker.png' },
    { branch: 'self-lover', url: '/card/self-lover.png' },
    { branch: 'progressive-learner', url: '/card/progressive-learner.png' },
    { branch: 'sad-adult', url: '/card/sad-adult.png' },
    { branch: 'bitter-adult', url: '/card/bitter-adult.png' },
  ]
  const result = find<typeof choice[0]>(choice, { branch })
  // return result ? result.url : null
  return result ? result.url : '/card/progressive-learner.png'
}

export function SaveCard() {
  const CARD_RENDER_HEIGHT = 400
  const CARD_WIDTH = 1052
  const CARD_HEIGHT = 1725

  const storage = useLocalStorage()
  const card_url = get_card_url(storage.story_branch)
  const name = storage.name
  const canvasStoryRef = React.useRef<HTMLCanvasElement>(null as any)
  const imageRef = React.useRef<HTMLImageElement>(null as any)

  const [storyCardState, setStoryCardState] = useState({
    loaded: false,
    url: '',
  })

  useEffect(() => {
    const ctx = canvasStoryRef.current.getContext('2d')
    const imageObj1 = new Image()
    imageObj1.src = card_url || ''
    console.log(imageObj1)
    if (ctx) {
      imageObj1.onload = () => {
        const imgWidth = CARD_WIDTH
        const imgHeight = CARD_HEIGHT
        ctx.canvas.width = imgWidth
        ctx.canvas.height = imgHeight
        ctx.drawImage(imageObj1, 0, 0, imgWidth, imgHeight)

        ctx.font = `bold ${80}px Wittaya`
        const textString = name
        // const textWidth = ctx.measureText(textString).width
        ctx.fillStyle = '#68d391'
        ctx.fillText(textString, 120, 150)

        setStoryCardState({ ...storyCardState, loaded: true })
      }
    }
  }, [])

  useEffect(() => {
    const bodyEl = document.getElementById('body')
    if (bodyEl) {
      bodyEl.className = ''
    }
  }, [])
  useEffect(() => {
    const dataUrl = canvasStoryRef.current.toDataURL('image/png', 1)
    imageRef.current.src = dataUrl
  }, [storyCardState])

  return (
    <div
      id="image-holder"
      className="fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center"
      style={{ backgroundColor: '#f27a22' }}
    >
      <div className="font-bold text-white mb-2">กดค้างเพื่อบันทึกรูป</div>
      {!storyCardState.loaded && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={(CARD_WIDTH * CARD_RENDER_HEIGHT) / CARD_HEIGHT}
          height={CARD_RENDER_HEIGHT}
        />
      )}
      <img
        ref={imageRef}
        alt="image-save"
        className="rounded-xl shadow-xl"
        style={{ maxHeight: '60%', pointerEvents: 'auto', zIndex: 2 }}
      />
      <a href="/?index=endline_card" className="font-bold text-white mt-2">
        กลับไปยังหน้าหลัก
      </a>
      <canvas
        id="image-canvas"
        ref={canvasStoryRef}
        style={{
          maxWidth: (CARD_WIDTH * CARD_RENDER_HEIGHT) / CARD_HEIGHT,
          width: (CARD_WIDTH * CARD_RENDER_HEIGHT) / CARD_HEIGHT,
          height: CARD_RENDER_HEIGHT,
        }}
        className={`fixed invisible bottom-0 left-0 ${storyCardState.loaded ? 'opacity-1' : 'opacity-0'}`}
      />
    </div>
  )
}
